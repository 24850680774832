import './exams.css'
import React, { useEffect, useState } from 'react';
import { BiCheck } from 'react-icons/bi'

const Exams = () => {
    const [averageGrades, setAverageGrades] = useState([]);

    useEffect(() => {
        const calculateAverageGrades = () => {
            const examElements = document.querySelectorAll('.exam__list');
            const averages = [];

            examElements.forEach((examElement) => {
                const gradeElements = examElement.querySelectorAll('p');
                const grades = [];

                gradeElements.forEach((gradeElement) => {
                    const match = gradeElement.textContent.match(/\((\d+)(L)?\)/); // Trova il numero tra parentesi, incluso "L" opzionale
                    if (match) {
                        let grade = parseInt(match[1]); // Converte il numero in intero

                        if (match[2] === 'L') {
                            grade = 33; // Se è presente "L", considera il voto come 33
                        }

                        grades.push(grade);
                    }
                });

                const validGrades = grades.filter((grade) => !isNaN(grade)); // Filtra solo i voti numerici

                const sum = validGrades.reduce((acc, grade) => acc + grade, 0);
                const average = sum / validGrades.length;
                averages.push(average.toFixed(2)); // Arrotonda a 2 decimali
            });

            return averages;
        };

        const calculatedGrades = calculateAverageGrades();
        setAverageGrades(calculatedGrades);
    }, []);
    return (
        <section id='exams'>
            <h5>Passed Exams</h5>
            <h2>Exams</h2>
            <div className="container exams__container">
                <article className="exam">
                    <div className="exam__head">
                        <h3>First Year</h3>
                    </div>
                    <ul className='exam__list'>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Algebra e Logica per Informatica (24)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Algoritmi e Strutture Dati (22)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Architettura dei Calcolatori (27)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Calculus 1 (24)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Introduzione alla programmazione (26)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Lingua Inglese 1 (idoneo)</p>
                        </li>
                    </ul>
                    <p className='exams_average'> Average Grade: {averageGrades[0]}</p>
                </article>

                <article className="exam">
                    <div className="exam__head">
                        <h3>Second Year</h3>
                    </div>
                    <ul className='exam__list'>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Algebra Lineare e Analisi Numerica (20)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Analisi e Progettazione di Algoritmi (30)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Basi di Dati (25)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Linguaggi di Programmazione Orientata agli Oggetti (22)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Sistemi di Elaborazione e Trasmissione dell'Informazione (24)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Teoria dell'Informazione e Inferenza (28)</p>
                        </li>
                    </ul>
                    <p className='exams_average'> Average Grade: {averageGrades[1]}</p>
                </article>

                <article className="exam">
                    <div className="exam__head">
                        <h3>Third Year</h3>
                    </div>
                    <ul className='exam__list'>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Computer Security (26)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Fondamenti di Computazione Quantistica (30)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Sviluppo di
                                Applicazioni Web (29)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Calculus 2 (28)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Fondamenti dell'Elaborazione di Segnali e Immagini (25)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Introduzione alla Data Science (30L)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Programmazione Concorrente e Algoritmi Distribuiti (23)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Fondamenti di Ingegneria del Software (23)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Teoria degli Automi e Calcolabilità (29)</p>
                        </li>
                    </ul>
                    <p className='exams_average'> Average Grade: {averageGrades[2]}</p>
                </article>

                <article className="exam">
                    <div className="exam__head">
                        <h3>Fourth Year</h3>
                    </div>
                    <ul className='exam__list'>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Distributed Computing (30)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Data Protection and Privacy (30L)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Machine Learning and Data Analysis (30)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Network Analysis (30L)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Functional and Security Testing Techniques (30L)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Digital Forensics (30)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Mobile Development (30)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Internet of Things (30L)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Virtualization and Cloud Computing (30L)</p>
                        </li>
                    </ul>
                    <p className='exams_average'> Average Grade: {averageGrades[3]}</p>
                </article>

                <article className="exam">
                    <div className="exam__head">
                        <h3>Fifth Year</h3>
                    </div>
                    <ul className='exam__list'>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Blockchain and Distributed Ledger (30)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Binary Analysis and Secure Coding (30L)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>High Performance Computing (30L)</p>
                        </li>
                        <li>
                            <BiCheck className='exam__list-icon' />
                            <p>Capstone Project (30)</p>
                        </li>
                    </ul>
                    <p className='exams_average'> Average Grade: {averageGrades[4]}</p>
                </article> 
            </div>
        </section>
    )
}
export default Exams