import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Portfolio from './components/portfolio/Portfolio';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Experience from './components/experience/Experience';
import Exams from './components/exams/Exams';
import Involved from './components/involved/Involved';
import Misc from './components/misc/Misc';
import Notes from './components/notes/Notes';
import Clips from './components/clips/Clips';

// initialize google analytics
ReactGA4.initialize("G-HTD3THZJGM");

const MainLayout = () => {
    return (
        <>
            <Header />
            <Nav />
            <About />
            <Experience />
            <Exams />
            <Portfolio />
            <Involved />
            <Misc />
            <Contact />
            <Footer />
        </>
    );
};

const NotesLayout = () => {
    return (
        <>
            <Notes />
            <Footer />
        </>
    );
};

const ClipsLayout = () => {
    return (
        <>
            <Clips />
            <Footer />
        </>
    );
};

const App = () => {
    // get google analytics data using react-ga4 library
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname + location.search);
        // send page view to google analytics
        ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);

    return (
        <Routes>
            <Route path="/notes" element={<NotesLayout />} />
            <Route path="/clips" element={<ClipsLayout />} />
            <Route path="/" element={<MainLayout />} />
        </Routes>

    );
};

export default App;
